'use client';
import { type CircleLayer, Layer, Source } from 'react-map-gl';

const metersToPixelsAtMaxZoom = (meters: number, latitude: number) =>
  meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
export interface ActivityTrackLayerProps {
  tracks: GeoJSON.FeatureCollection;
}
export function ActivityTrackLayer({ tracks }: ActivityTrackLayerProps) {
  const geojson = tracks;
  if (!geojson) return null;

  const pointColor = '#f5a623';
  const activityLayer: CircleLayer = {
    id: 'activity',
    type: 'circle',
    paint: {
      'circle-color': pointColor,
    },
  };
  const pointRadiusLayer: CircleLayer = {
    id: 'pointRadius',
    type: 'circle',
    paint: {
      'circle-opacity': 0.1,
      'circle-color': pointColor,
      'circle-radius': {
        stops: [
          [0, 0],
          [
            20,
            metersToPixelsAtMaxZoom(
              25,
              tracks?.[0]?.features.geometry?.coordinates[1] ?? 0,
            ),
          ],
        ],
        base: 2,
      },
    },
  };

  return (
    <Source id="activity" type="geojson" data={geojson}>
      <Layer {...activityLayer} beforeId="_progress_underlay" />
      <Layer {...pointRadiusLayer} beforeId="_progress_underlay" />
    </Source>
  );
}
